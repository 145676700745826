import { lookupSelectors } from "./lookups.reducers";
import { authSelectors } from "./auth.reducers";
import { userSelectors } from "./user.reducers";
import { notificationSelectors } from "./notifications.reducers";
import { matchesSelectors } from "./matches.reducers";
import { communicationsSelectors } from "./communications.reducers";
import { premiumSelectors } from "./premium.reducers";
import { productsSelectors } from "./products.reducers";

//lookups
const getAllLookups = (state) => state.lookups;
const getGenders = (state) => lookupSelectors.genders(state.lookups);
const getMaritalStatuses = (state) =>
  lookupSelectors.maritalStatuses(state.lookups);
const getCountries = (state) => lookupSelectors.countries(state.lookups);
const getCountryStates = (state) =>
  lookupSelectors.countryStates(state.lookups);
const getStateCities = (state) => lookupSelectors.stateCities(state.lookups);
const getAnnualIncome = (state) => lookupSelectors.annualIncome(state.lookups);
const getChildren = (state) => lookupSelectors.children(state.lookups);
const getCollege = (state) => lookupSelectors.college(state.lookups);
const getEducation = (state) => lookupSelectors.education(state.lookups);
const getEmployer = (state) => lookupSelectors.employer(state.lookups);
const getFamilyCommunity = (state) =>
  lookupSelectors.familyCommunity(state.lookups);
const getHeightInFt = (state) => lookupSelectors.heightInFt(state.lookups);
const getHeightInInches = (state) =>
  lookupSelectors.heightInInches(state.lookups);
const getLanguage = (state) => lookupSelectors.language(state.lookups);
const getProfession = (state) => lookupSelectors.profession(state.lookups);
const getProfileFor = (state) => lookupSelectors.profileFor(state.lookups);
const getReligion = (state) => lookupSelectors.religion(state.lookups);
const getDisability = (state) => lookupSelectors.disability(state.lookups);
const getReligionCommunity = (state) =>
  lookupSelectors.religionCommunity(state.lookups);
const getCommunity = (state) => lookupSelectors.community(state.lookups);
const getEthnicOrigin = (state) => lookupSelectors.ethnicOrigin(state.lookups);
const getResidencyStatus = (state) =>
  lookupSelectors.residencyStatus(state.lookups);
const getSiblings = (state) => lookupSelectors.siblings(state.lookups);

//auth
const userSignupResponse = (state) => authSelectors.signupResponse(state.auth);
const loginOtpRequestResponse = (state) =>
  authSelectors.loginOtpRequestResponse(state.auth);
const loginFacebookResponse = (state) =>
  authSelectors.loginFacebookResponse(state.auth);
const loggedInWithEmail = (state) =>
  authSelectors.loggedInWithEmail(state.auth);
const isOtpVerified = (state) => authSelectors.otpVerified(state.auth);
const forgotPasswordResponse = (state) =>
  authSelectors.forgotPasswordResponse(state.auth);
const resetPasswordResponse = (state) =>
  authSelectors.resetPasswordResponse(state.auth);
const authLoading = (state) => authSelectors.loading(state.auth);
const authError = (state) => authSelectors.error(state.auth);
//user
const getUser = (state) => userSelectors.user(state.user);
const getUserCurrency = (state) => {
  const user = getUser(state);
  if (!user) return null;

  //if (user.country.toLowerCase() === "pakistan") return "pkr";
  if (user.country === "pakistan") return "pkr";

  return "usd";
};
const getPartnerPreferences = (state) =>
  userSelectors.partnerPreferences(state.user);
const isUserUpdated = (state) => userSelectors.isUpdated(state.user);
const gettingUser = (state) => userSelectors.gettingUser(state.user);
const getUserPictures = (state) => userSelectors.userPictures(state.user);
const userLoading = (state) => userSelectors.loading(state.user);
const getSubscriptionDetails = (state) =>
  userSelectors.subscriptionDetails(state.user);
//notification
const getNotifications = (state) =>
  notificationSelectors.notifications(state.notifications);

//matches
const loadingMatches = (state) => matchesSelectors.loading(state.matches);
const matchInfoShown = (state) =>
  matchesSelectors.matchInfoShown(state.matches);
const getMatches = (state) => matchesSelectors.matches(state.matches);
const getAcceptedQaboolHaiMatches = (state) =>
  matchesSelectors.acceptedQaboolHaiMatches(state.matches);
const getSentPendingQaboolHaiMatches = (state) =>
  matchesSelectors.sentPendingQaboolHaiMatches(state.matches);
const getReceivedPendingQaboolHaiMatches = (state) =>
  matchesSelectors.receivedPendingQaboolHaiMatches(state.matches);
const getFavoriteMatches = (state) =>
  matchesSelectors.favoriteMatches(state.matches);
const getVisitedByMatches = (state) =>
  matchesSelectors.visitedByMatches(state.matches);
const getPassedMatches = (state) =>
  matchesSelectors.passedMatches(state.matches);
const getVisitedMatches = (state) =>
  matchesSelectors.visitedMatches(state.matches);
const getBlockedMatches = (state) =>
  matchesSelectors.blockedMatches(state.matches);
const performingMatchAction = (state) =>
  matchesSelectors.performingMatchAction(state.matches);
const getMatchActionResponse = (state) =>
  matchesSelectors.matchActionResponse(state.matches);

//communications
const isPushEnabled = (state) =>
  communicationsSelectors.pushEnabled(state.communications);
const loadingCommData = (state) =>
  communicationsSelectors.loading(state.communications);
const loadingChats = (state) =>
  communicationsSelectors.loadingChats(state.communications);
const loadingMessages = (state) =>
  communicationsSelectors.loadingMessages(state.communications);
const getUserNotifications = (state) =>
  communicationsSelectors.notifications(state.communications);
const sendingMessage = (state) =>
  communicationsSelectors.sendingMessage(state.communications);
const getRecipientMessages = (userId) => (state) =>
  communicationsSelectors.messages(state.communications, userId);
const getRecentChats = (state) =>
  communicationsSelectors.recentChats(state.communications);
const getIncomingCallData = (state) =>
  communicationsSelectors.incomingCallData(state.communications);
const getOutgoingCallData = (state) =>
  communicationsSelectors.outgoingCallData(state.communications);
const initiatingCall = (state) =>
  communicationsSelectors.initiatingCall(state.communications);
const getInitCallResponse = (state) =>
  communicationsSelectors.initCallResponse(state.communications);
const gettingCallAuthToken = (state) =>
  communicationsSelectors.gettingCallAuthToken(state.communications);
const getCallAuthTokenResp = (state) =>
  communicationsSelectors.callAuthTokenResp(state.communications);

//premium feature
const getPremiumFeatureRequest = (state) =>
  premiumSelectors.premiumFeatureRequest(state.premium);
const isUnlockingPremiumFeature = (state) =>
  premiumSelectors.unlockingFeature(state.premium);
const getPremiumFeatureResponse = (state) =>
  premiumSelectors.premiumFeatureResponse(state.premium);

//products
const getPremiumPackages = (state) =>
  productsSelectors.premiumPackages(state.products);
const getAddOns = (state) =>
  productsSelectors.addOns(state.products, getUserCurrency(state));
const getGoldPackages = (state) =>
  productsSelectors.goldPackages(state.products, getUserCurrency(state));
const getPlatinumPackages = (state) =>
  productsSelectors.platinumPackages(state.products, getUserCurrency(state));

export default {
  //lookups
  getAllLookups,
  getGenders,
  getMaritalStatuses,
  getCountries,
  getCountryStates,
  getStateCities,
  getAnnualIncome,
  getChildren,
  getCollege,
  getEducation,
  getEmployer,
  getFamilyCommunity,
  getHeightInFt,
  getHeightInInches,
  getLanguage,
  getProfession,
  getProfileFor,
  getReligion,
  getDisability,
  getReligionCommunity,
  getCommunity,
  getEthnicOrigin,
  getResidencyStatus,
  getSiblings,

  //auth
  userSignupResponse,
  loginOtpRequestResponse,
  loginFacebookResponse,
  loggedInWithEmail,
  isOtpVerified,
  forgotPasswordResponse,
  resetPasswordResponse,
  authLoading,
  authError,
  //user
  getUser,
  getPartnerPreferences,
  isUserUpdated,
  gettingUser,
  getUserPictures,
  userLoading,
  getSubscriptionDetails,
  //notification
  getNotifications,

  //matches
  loadingMatches,
  matchInfoShown,
  getMatches,
  getAcceptedQaboolHaiMatches,
  getSentPendingQaboolHaiMatches,
  getReceivedPendingQaboolHaiMatches,
  getFavoriteMatches,
  getVisitedByMatches,
  getPassedMatches,
  getVisitedMatches,
  getBlockedMatches,
  performingMatchAction,
  getMatchActionResponse,

  //communications
  isPushEnabled,
  loadingCommData,
  loadingChats,
  loadingMessages,
  getUserNotifications,
  sendingMessage,
  getRecipientMessages,
  getRecentChats,
  getIncomingCallData,
  getOutgoingCallData,
  initiatingCall,
  getInitCallResponse,
  gettingCallAuthToken,
  getCallAuthTokenResp,

  //premium feature
  getPremiumFeatureRequest,
  isUnlockingPremiumFeature,
  getPremiumFeatureResponse,

  //products
  getPremiumPackages,
  getAddOns,
  getGoldPackages,
  getPlatinumPackages,
};
