import React from 'react';
import { Provider } from 'react-redux';
import { history, store } from './store';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';

const AppBase = () => (
  <Provider store={store}>
    <App />
    {/* <ConnectedRouter history={history}>
    </ConnectedRouter> */}
  </Provider>
);

export default AppBase;
