import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from './translation/locales/en/translation.json';
import translationUr from './translation/locales/ur/translation.json';
const resources = {
  en: {
    translation: translationEn,
  },
  ur: {
    translation: translationUr,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources,
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    nonExplicitSupportedLngs: false,
    cleanCode: true,
  });

export const getLanguage = () => {
  return (
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en'
  );
};
