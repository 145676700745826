import produce, { current } from "immer";
import actionNames from "./actionNames";
// import differenceInYears from "date-fns/differenceInYears";
// import { parseAPITime } from "../shared/utils";
// import { YYYYMMDD } from "../shared/dateFormats";

//convert  height 
const heightToLookups = (inches) => ({
  heightInFeetId: inches ? Math.floor(inches / 12) : "",
  heightInInchesId: inches ? Math.floor(inches % 12) : "",
});

// transform null to empty string
const nullToString = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = "";
    }
  });
  return obj;
};

//initial user Model State initiated as Starting state for the app.
const initialState = {
  loadingUser: false,
  loading: false,
  isUpdated: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    heightInFeetId: "",
    heightInInchesId: "",
    about: "",
    genderId: "",
    maritalStatusId: "",
    profileForId: "",
    languageIds: [],
    religionId: "",
    religionCommunityId: "",
    familyCommunityId: "",
    countryId: "",
    countryStateId: "", //missing
    countryCityId: "", //missing
    professionId: "",
    educationId: "", //missing
    employerId: "",
    annualIncomeId: "",
    hasDisability: "",
    disabilityId: "",
    collegeId: "",
    completionPercentage: "",
    communityId: "",
    ethnicOriginId: "",
    residencyStatusId: "",
    siblingsId: "",

    profilePictureId: "", //skip
    profilePictureURL: "", //skip

    createdDate: "", //skip
    lastModifiedDate: "", //skip
    isActive: false, //skip
    isDeleted: false, //skip
    isLocked: false, //skip
    isDisable: false, //skips
    id: "", //skip
  },
  partnerPreferences: {
    minAge: null,
    maxAge: null,
    minHeightInInches: "",
    maxHeightInInches: "",
    children: "",
    maritalStatusIds: [],
    religionIds: [],
    religionCommunityIds: [],
    professionIds: [],
    familyCommunityIds: [],
    languageIds: [],
    educationIds: [],
    countryLivingInIds: [],
    countryStateIds: [],

    id: "", //skip
    createdDate: null, //skip
    lastModifiedDate: null, //skip
  },
  userPictures: [],
  subscriptionDetails: null,
};

const reducer = produce((newState, action) => {
  const state = current(newState);
  const { type: actionType, payload } = action;

  switch (actionType) {
    
    default:
      //Do nothing (REmoved Warnings)
      break;

    case actionNames.GET_USER_LOADING:
      newState.loadingUser = true;
      break;

    case actionNames.UPDATE_USER_LOADING:
    case actionNames.GET_PARTNER_PREFERENCES_LOADING:
    case actionNames.UPDATE_PARTNER_PREFERENCES_LOADING:
    case actionNames.GETTING_USER_PICTURES_LOADING:
    case actionNames.VERIFY_USER_LOADING:
    case actionNames.GET_SUBSCRIPTION_DETAILS_LOADING:
      newState.loading = true;
      newState.isUpdated = false;
      break;

    case actionNames.GET_USER_FAILED:
      newState.loadingUser = false;
      break;

    case actionNames.RESET_USER_UPDATED:
    case actionNames.UPDATE_USER_FAILED:
    case actionNames.GET_PARTNER_PREFERENCES_FAILED:
    case actionNames.UPDATE_PARTNER_PREFERENCES_FAILED:
    case actionNames.GETTING_USER_PICTURES_FAILED:
    case actionNames.VERIFY_USER_FAILED:
    case actionNames.GET_SUBSCRIPTION_DETAILS_FAILED:
      newState.loading = false;
      newState.isUpdated = false;
      break;

    case actionNames.GET_USER_SUCCESS:
      newState.loadingUser = false;
      newState.user = {
        ...initialState.user,
        ...nullToString(payload),
        ...heightToLookups(payload.heightInInches),
        isMale: payload.genderId === 1,
        languageIds: payload.languageIds || [],
        // age: payload.dateOfBirth
        //   ? differenceInYears(
        //       new Date(),
        //       parseAPITime(payload.dateOfBirth.split("T")[0], YYYYMMDD)
        //     )
        //   : 0,
        isPremiumMember: true,
      };
      break;

    case actionNames.UPDATE_USER_SUCCESS:
      newState.loading = false;
      newState.isUpdated = true;
      newState.user = {
        ...state.user,
        ...nullToString(payload),
      };
      break;

    case actionNames.GET_PARTNER_PREFERENCES_SUCCESS:
      newState.loading = false;
      newState.isUpdated = false;

      newState.partnerPreferences = {
        ...initialState.partnerPreferences,
        ...nullToString(payload),
      };
      
      newState.partnerPreferences.maritalStatusIds = payload.maritalStatusIds || [];
      newState.partnerPreferences.religionIds = payload.religionIds || [];
      newState.partnerPreferences.religionCommunityIds = payload.religionCommunityIds || [];
      newState.partnerPreferences.professionIds = payload.professionIds || [];
      newState.partnerPreferences.familyCommunityIds = payload.familyCommunityIds || [];
      newState.partnerPreferences.languageIds = payload.languageIds || [];
      newState.partnerPreferences.educationIds = payload.educationIds || [];
      newState.partnerPreferences.countryLivingInIds =  payload.countryLivingInIds || [];
      newState.partnerPreferences.countryStateIds = payload.countryStateIds || [];
      break;

    case actionNames.UPDATE_PARTNER_PREFERENCES_SUCCESS:
      newState.loading = false;
      newState.isUpdated = true;

      newState.partnerPreferences = {
        ...state.partnerPreferences,
        ...payload,
      };
      break;

    case actionNames.GETTING_USER_PICTURES_SUCCESS:
      newState.loading = false;

      newState.userPictures = payload.reverse();
      break;

    case actionNames.VERIFY_USER_SUCCESS:
      newState.loading = false;
      newState.isUpdated = true;
      break;

    case actionNames.GET_SUBSCRIPTION_DETAILS_SUCCESS:
      newState.loading = false;
      newState.subscriptionDetails = payload;
      break;

    case actionNames.USER_UNLOCKED_FEATURE_SUCCESS:
      if (!state.subscriptionDetails) return;
      newState.subscriptionDetails = {
        ...state.subscriptionDetails,
        [payload.feature]: state.subscriptionDetails[payload.feature] - 1,
      };
    }
}, initialState);

export const userSelectors = {
  loading: (state) => state.loading,
  user: (state) => state.user,
  partnerPreferences: (state) => state.partnerPreferences,
  userPictures: (state) => state.userPictures,
  isUpdated: (state) => state.isUpdated,
  gettingUser: (state) => state.loadingUser,
  subscriptionDetails: (state) => state.subscriptionDetails,
};

export default reducer;
