import produce from "immer";
import actionNames from "./actionNames";

const initialState = {
  unlockingFeature: false,
  premiumFeatureRequest: null,
  premiumFeatureResponse: null,
};

const reducer = produce((newState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case actionNames.UNLOCK_PREMIUM_FEATURE_REQUEST:
      newState.premiumFeatureRequest = payload;
      break;
    case actionNames.RESET_UNLOCK_PREMIUM_FEATURE_RESPONSE:
      newState.premiumFeatureRequest = null;
      newState.premiumFeatureResponse = null;
      break;

    case actionNames.UNLOCK_PREMIUM_FEATURE_LOADING:
      newState.unlockingFeature = true;
      newState.premiumFeatureResponse = null;
      break;
    case actionNames.UNLOCK_PREMIUM_FEATURE_FAILED:
      newState.unlockingFeature = false;
      newState.premiumFeatureRequest = null;
      newState.premiumFeatureResponse = null;
      break;
    case actionNames.UNLOCK_PREMIUM_FEATURE_SUCCESS:
      newState.unlockingFeature = false;
      newState.premiumFeatureResponse = payload;
      break;
    default:
  }
}, initialState);

export const premiumSelectors = {
  premiumFeatureRequest: (state) => state.premiumFeatureRequest,
  unlockingFeature: (state) => state.unlockingFeature,
  premiumFeatureResponse: (state) => state.premiumFeatureResponse,
};

export default reducer;
