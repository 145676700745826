import produce from "immer";
import actionNames from "./actionNames";

const initialState = {
  loading: false,
  premiumPackages: null,
  addOns: null,
};

const reducer = produce((newState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case actionNames.GET_PRODUCTS_LOADING:
      newState.loading = true;
      break;
    case actionNames.GET_PRODUCTS_FAILED:
      newState.loading = false;
      break;
    case actionNames.GET_PRODUCTS_SUCCESS:
      newState.loading = false;
      newState.premiumPackages = payload.recurring;
      newState.addOns = payload.one_time;
      break;
    default:
  }
}, initialState);

const premiumPackages = (state) => state.premiumPackages;
const allAaddOns = (state) => state.addOns;

const getAddOns = (state, currency) => {
  const { one_time: addOns } = allAaddOns(state)?.[currency] || {};
  if (!addOns) return {};

  return Object.keys(addOns).reduce((groupedAddOns, packageName) => {
    const addOn = addOns[packageName][0];
    let feature = addOn.metadata.package.replace(" ", "_");
    if (feature === "videocalls") feature = "videoCalls";

    (groupedAddOns[feature] || (groupedAddOns[feature] = [])).push(addOn);
    return groupedAddOns;
  }, {});
};

const getPackages = (state, currency, packageName) => {
  const packages = premiumPackages(state)?.[currency] || [];
  return Object.keys(packages).map((duration) => {
    const packageDetails = packages[duration][packageName][0];
    return {
      ...packageDetails,
      metadata: {
        ...packageDetails.metadata,
        videocalls: undefined,
        videoCalls: packageDetails.metadata.videocalls,
      },
    };
  });
};

export const productsSelectors = {
  premiumPackages,
  addOns: getAddOns,
  goldPackages: (state, currency) => getPackages(state, currency, "gold"),
  platinumPackages: (state, currency) =>
    getPackages(state, currency, "platinum"),
};

export default reducer;
