import actionNames from "../actionNames";
import selectors from "../selectors";

const authorizedAction = (action, authorized) => ({
  ...action,
  payload: {
    ...action.payload,
    authorized,
  },
});

const authorizeFreeUser = (feature, subscriptionDetails) => {
  if (feature !== "swipes") {
    return false;
  }

  return subscriptionDetails.swipes > 0;
};
const authoizeGoldUser = (feature, subscriptionDetails) => {
  if (feature === "resetSwipes") return subscriptionDetails[feature];

  if (feature === "swipes") {
    return subscriptionDetails.unlimitedSwipes;
  }

  return subscriptionDetails[feature] > 0;
};

export default ({ getState }) => (next) => async (action) => {
  if (
    typeof action !== "object" ||
    action.type !== actionNames.UNLOCK_PREMIUM_FEATURE_REQUEST
  ) {
    return next(action);
  }

  const state = getState();
  const { payload } = action;
  const { feature } = payload;
  const userSubDetails = selectors.getSubscriptionDetails(state);
  if (userSubDetails.level === "free")
    return next(
      authorizedAction(action, authorizeFreeUser(feature, userSubDetails))
    );

  if (userSubDetails.level === "gold")
    return next(
      authorizedAction(action, authoizeGoldUser(feature, userSubDetails))
    );

  return next(authorizedAction(action, true, null));
};
