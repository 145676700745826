// import { random } from "../shared/utils";

const actionNames = {
  //lookups
  LOAD_LOOKUPS_LOADING: "LOAD_LOOKUPS_LOADING",
  LOAD_LOOKUPS_SUCCESS: "LOAD_LOOKUPS_SUCCESS",
  LOAD_LOOKUPS_FAILED: "LOAD_LOOKUPS_FAILED",

  //auth
  SIGNUP_USER_LOADING: "SIGNUP_USER_LOADING",
  SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILED: "SIGNUP_USER_FAILED",
  SIGNUP_USER_RESET: "SIGNUP_USER_RESET",

  LOGIN_MOBILE_LOADING: "LOGIN_MOBILE_LOADING",
  LOGIN_MOBILE_SUCCESS: "LOGIN_MOBILE_SUCCESS",
  LOGIN_MOBILE_FAILED: "LOGIN_MOBILE_FAILED",
  LOGIN_MOBILE_RESET: "LOGIN_MOBILE_RESET",

  LOGIN_FACEBOOK_LOADING: "LOGIN_FACEBOOK_LOADING",
  LOGIN_FACEBOOK_SUCCESS: "LOGIN_FACEBOOK_SUCCESS",
  LOGIN_FACEBOOK_FAILED: "LOGIN_FACEBOOK_FAILED",
  LOGIN_FACEBOOK_RESET: "LOGIN_FACEBOOK_RESET",

  LOGIN_EMAIL_LOADING: "LOGIN_EMAIL_LOADING",
  LOGIN_EMAIL_SUCCESS: "LOGIN_EMAIL_SUCCESS",
  LOGIN_EMAIL_FAILED: "LOGIN_EMAIL_FAILED",
  LOGIN_EMAIL_RESET: "LOGIN_EMAIL_RESET",

  FORGOT_PASSWORD_LOADING: "FORGOT_PASSWORD_LOADING",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
  FORGOT_PASSWORD_RESET: "FORGOT_PASSWORD_RESET",

  RESET_PASSWORD_LOADING: "RESET_PASSWORD_LOADING",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
  RESET_PASSWORD_RESET: "RESET_PASSWORD_RESET",

  VERIFY_OTP_LOADING: "VERIFY_OTP_LOADING",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILED: "VERIFY_OTP_FAILED",
  VERIFY_OTP_RESET: "VERIFY_OTP_RESET",

  //user
  GET_USER_LOADING: "GET_USER_LOADING",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILED: "GET_USER_FAILED",

  UPDATE_USER_LOADING: "UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  RESET_USER_UPDATED: "RESET_USER_UPDATED",

  UPLOADING_USER_PROFILE_IMAGE_LOADING: "UPLOADING_USER_PROFILE_IMAGE_LOADING",
  UPLOADING_USER_PROFILE_IMAGE_SUCCESS: "UPLOADING_USER_PROFILE_IMAGE_SUCCESS",
  UPLOADING_USER_PROFILE_IMAGE_FAILED: "UPLOADING_USER_PROFILE_IMAGE_FAILED",

  GET_PARTNER_PREFERENCES_LOADING: "GET_PARTNER_PREFERENCES_LOADING",
  GET_PARTNER_PREFERENCES_SUCCESS: "GET_PARTNER_PREFERENCES_SUCCESS",
  GET_PARTNER_PREFERENCES_FAILED: "GET_PARTNER_PREFERENCES_FAILED",

  UPDATE_PARTNER_PREFERENCES_LOADING: "UPDATE_PARTNER_PREFERENCES_LOADING",
  UPDATE_PARTNER_PREFERENCES_SUCCESS: "UPDATE_PARTNER_PREFERENCES_SUCCESS",
  UPDATE_PARTNER_PREFERENCES_FAILED: "UPDATE_PARTNER_PREFERENCES_FAILED",

  GETTING_USER_PICTURES_LOADING: "GETTING_USER_PICTURES_LOADING",
  GETTING_USER_PICTURES_SUCCESS: "GETTING_USER_PICTURES_SUCCESS",
  GETTING_USER_PICTURES_FAILED: "GETTING_USER_PICTURES_FAILED",

  UPLOADING_USER_PICTURE_LOADING: "UPLOADING_USER_PICTURE_LOADING",
  UPLOADING_USER_PICTURE_SUCCESS: "UPLOADING_USER_PICTURE_SUCCESS",
  UPLOADING_USER_PICTURE_FAILED: "UPLOADING_USER_PICTURE_FAILED",

  REMOVING_USER_PICTURE_LOADING: "REMOVING_USER_PICTURE_LOADING",
  REMOVING_USER_PICTURE_SUCCESS: "REMOVING_USER_PICTURE_SUCCESS",
  REMOVING_USER_PICTURE_FAILED: "REMOVING_USER_PICTURE_FAILED",

  VERIFY_USER_LOADING: "VERIFY_USER_LOADING",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_FAILED: "VERIFY_USER_FAILED",

  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",

  USER_UNLOCKED_FEATURE_LOADING: "USER_UNLOCKED_FEATURE_LOADING",
  USER_UNLOCKED_FEATURE_SUCCESS: "USER_UNLOCKED_FEATURE_SUCCESS",
  USER_UNLOCKED_FEATURE_FAILED: "USER_UNLOCKED_FEATURE_FAILED",

  // matches
  GET_MATCHES_LOADING: "GET_MATCHES_LOADING",
  GET_MATCHES_SUCCESS: "GET_MATCHES_SUCCESS",
  GET_MATCHES_FAILED: "GET_MATCHES_FAILED",

  MATCH_ACTION_LOADING: "MATCH_ACTION_LOADING",
  MATCH_ACTION_SUCCESS: "MATCH_ACTION_SUCCESS",
  MATCH_ACTION_FAILED: "MATCH_ACTION_FAILED",
  RESET_MATCH_ACTION_RESPONSE: "RESET_MATCH_ACTION_RESPONSE",
  SET_MATCH_INFO_SHOWN: "SET_MATCH_INFO_SHOWN",

  // communications
  GET_NOTIFICATIONS_LOADING: "GET_NOTIFICATIONS_LOADING",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",

  NOTIFICATION_EXECUTED: "NOTIFICATION_EXECUTED",

  SET_PUSH_API_ENABLED: "SET_PUSH_API_ENABLED",
  SET_PUSH_API_FAILED: "SET_PUSH_API_FAILED",

  SET_MESSAGE_RECEIVED: "SET_MESSAGE_RECEIVED",

  SET_MESSAGE_READ: "SET_MESSAGE_READ",

  SEND_MESSAGES_LOADING: "SEND_MESSAGES_LOADING",
  SEND_MESSAGES_SUCCESS: "SEND_MESSAGES_SUCCESS",
  SEND_MESSAGES_FAILED: "SEND_MESSAGES_FAILED",

  GET_MESSAGES_LOADING: "GET_MESSAGES_LOADING",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  GET_MESSAGES_FAILED: "GET_MESSAGES_FAILED",

  GET_RECENT_CHATS_LOADING: "GET_RECENT_CHATS_LOADING",
  GET_RECENT_CHATS_SUCCESS: "GET_RECENT_CHATS_SUCCESS",
  GET_RECENT_CHATS_FAILED: "GET_RECENT_CHATS_FAILED",

  OUTGOING_VIDEO_CALL: "OUTGOING_VIDEO_CALL",
  INITIATE_VIDEO_CALL_LOADING: "INITIATE_VIDEO_CALL_LOADING",
  INITIATE_VIDEO_CALL_SUCCESS: "INITIATE_VIDEO_CALL_SUCCESS",
  INITIATE_VIDEO_CALL_FAILED: "INITIATE_VIDEO_CALL_FAILED",
  INCOMING_VIDEO_CALL: "INCOMING_VIDEO_CALL",
  GETTING_VIDEO_CALL_TOKEN_LOADING: "GETTING_VIDEO_CALL_TOKEN_LOADING",
  GETTING_VIDEO_CALL_TOKEN_SUCCESS: "GETTING_VIDEO_CALL_TOKEN_SUCCESS",
  GETTING_VIDEO_CALL_TOKEN_FAILED: "GETTING_VIDEO_CALL_TOKEN_FAILED",

  RESET_CALL_STATE: "RESET_CALL_STATE",

  //PREMIUM
  GET_PREMIUM_PLANS_LOADING: "GET_PREMIUM_PLANS_LOADING",
  GET_PREMIUM_PLANS_SUCCESS: "GET_PREMIUM_PLANS_SUCCESS",
  GET_PREMIUM_PLANS_FAILED: "GET_PREMIUM_PLANS_FAILED",

  ACTIVATE_PREMIUM_PLAN_LOADING: "ACTIVATE_PREMIUM_PLAN_LOADING",
  ACTIVATE_PREMIUM_PLAN_SUCCESS: "ACTIVATE_PREMIUM_PLAN_SUCCESS",
  ACTIVATE_PREMIUM_PLAN_FAILED: "ACTIVATE_PREMIUM_PLAN_FAILED",

  UNLOCK_PREMIUM_FEATURE_REQUEST: "UNLOCK_PREMIUM_FEATURE_REQUEST",
  RESET_UNLOCK_PREMIUM_FEATURE_RESPONSE:"RESET_UNLOCK_PREMIUM_FEATURE_RESPONSE",

  UNLOCK_PREMIUM_FEATURE_LOADING: "UNLOCK_PREMIUM_FEATURE_LOADING",
  UNLOCK_PREMIUM_FEATURE_SUCCESS: "UNLOCK_PREMIUM_FEATURE_SUCCESS",
  UNLOCK_PREMIUM_FEATURE_FAILED: "UNLOCK_PREMIUM_FEATURE_FAILED",

  //PAYMENT
  GET_PRODUCTS_LOADING: "GET_PRODUCTS_LOADING",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILED: "GET_PRODUCTS_FAILED",

  GET_PAYMENT_SESSION_LOADING: "GET_PAYMENT_SESSION_LOADING",
  GET_PAYMENT_SESSION_SUCCESS: "GET_PAYMENT_SESSION_SUCCESS",
  GET_PAYMENT_SESSION_FAILED: "GET_PAYMENT_SESSION_FAILED",

  GET_SUBSCRIPTION_DETAILS_LOADING: "GET_SUBSCRIPTION_DETAILS_LOADING",
  GET_SUBSCRIPTION_DETAILS_SUCCESS: "GET_SUBSCRIPTION_DETAILS_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_FAILED: "GET_SUBSCRIPTION_DETAILS_FAILED",
};

export const _dispatch = (dispatch, type, payload) => dispatch({ type, payload });

export default actionNames;
