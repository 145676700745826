import produce from "immer";
import actionNames from "./actionNames";

const initialState = {
  loading: false,
  error: null,
  signupResponse: false,
  loginOtpRequestResponse: null,
  loginFacebookResponse: null,
  loggedInWithEmail: null,
  otpVerified: null,
  forgotPasswordResponse: null,
  resetPasswordResponse: null,
};

const reducer = produce((newState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    //signup
    case actionNames.SIGNUP_USER_LOADING:
      newState.loading = true;
      newState.error = null;
      newState.signupResponse = null;
      break;
    case actionNames.SIGNUP_USER_FAILED:
      newState.loading = false;
      newState.error = payload.message;
      newState.signupResponse = null;
      break;
    case actionNames.SIGNUP_USER_RESET:
      newState.loading = false;
      newState.error = null;
      newState.signupResponse = null;
      break;
    case actionNames.SIGNUP_USER_SUCCESS:
      newState.loading = false;
      newState.error = null;
      newState.signupResponse = payload;
      break;

    //mobile login
    case actionNames.LOGIN_MOBILE_LOADING:
      newState.loading = true;
      newState.error = null;
      newState.loginOtpRequestResponse = null;
      break;
    case actionNames.LOGIN_MOBILE_FAILED:
      newState.loading = false;
      newState.error = payload.message;
      newState.loginOtpRequestResponse = null;
      break;
    case actionNames.LOGIN_MOBILE_RESET:
      newState.loading = false;
      newState.error = null;
      newState.loginOtpRequestResponse = null;
      break;
    case actionNames.LOGIN_MOBILE_SUCCESS:
      newState.loading = false;
      newState.error = null;
      newState.loginOtpRequestResponse = payload;
      break;

    //facebook login
    case actionNames.LOGIN_FACEBOOK_LOADING:
      newState.loading = true;
      newState.error = null;
      newState.loginFacebookResponse = null;
      break;
    case actionNames.LOGIN_FACEBOOK_FAILED:
      newState.error = payload.message;
      newState.loading = false;
      newState.loginFacebookResponse = null;
      break;
    case actionNames.LOGIN_FACEBOOK_RESET:
      newState.error = null;
      newState.loading = false;
      newState.loginFacebookResponse = null;
      break;
    case actionNames.LOGIN_FACEBOOK_SUCCESS:
      newState.error = null;
      newState.loading = false;
      newState.loginFacebookResponse = payload;
      break;

    //email login
    case actionNames.LOGIN_EMAIL_LOADING:
      newState.error = null;
      newState.loading = true;
      newState.loggedInWithEmail = null;
      break;
    case actionNames.LOGIN_EMAIL_FAILED:
      newState.error = payload.message;
      newState.loading = false;
      newState.loggedInWithEmail = null;
      break;
    case actionNames.LOGIN_EMAIL_RESET:
      newState.error = null;
      newState.loading = false;
      newState.loggedInWithEmail = null;
      break;
    case actionNames.LOGIN_EMAIL_SUCCESS:
      newState.error = null;
      newState.loading = false;
      newState.loggedInWithEmail = payload;
      break;

    //verify otp
    case actionNames.VERIFY_OTP_LOADING:
      newState.error = null;
      newState.loading = true;
      newState.otpVerified = false;
      break;
    case actionNames.VERIFY_OTP_FAILED:
      newState.error = payload.message;
      newState.loading = false;
      newState.otpVerified = false;
      break;
    case actionNames.VERIFY_OTP_RESET:
      newState.error = null;
      newState.loading = false;
      newState.otpVerified = false;
      break;
    case actionNames.VERIFY_OTP_SUCCESS:
      newState.error = null;
      newState.loading = false;
      newState.otpVerified = payload;
      break;

    //forgot password
    case actionNames.FORGOT_PASSWORD_LOADING:
      newState.error = null;
      newState.loading = true;
      newState.forgotPasswordResponse = null;
      break;
    case actionNames.FORGOT_PASSWORD_FAILED:
      newState.error = payload.message;
      newState.loading = false;
      newState.forgotPasswordResponse = null;
      break;
    case actionNames.FORGOT_PASSWORD_RESET:
      newState.error = null;
      newState.loading = false;
      newState.forgotPasswordResponse = null;
      break;
    case actionNames.FORGOT_PASSWORD_SUCCESS:
      newState.error = null;
      newState.loading = false;
      newState.forgotPasswordResponse = payload;
      break;

    //reset password
    case actionNames.RESET_PASSWORD_LOADING:
      newState.error = null;
      newState.loading = true;
      newState.resetPasswordResponse = null;
      break;
    case actionNames.RESET_PASSWORD_FAILED:
      newState.error = payload.message;
      newState.loading = false;
      newState.resetPasswordResponse = null;
      break;
    case actionNames.RESET_PASSWORD_RESET:
      newState.error = null;
      newState.loading = false;
      newState.resetPasswordResponse = null;
      break;
    case actionNames.RESET_PASSWORD_SUCCESS:
      newState.error = null;
      newState.loading = false;
      newState.resetPasswordResponse = payload;
      break;
  }
}, initialState);

export const authSelectors = {
  error: (state) => state.error,
  loading: (state) => state.loading,
  signupResponse: (state) => state.signupResponse,
  loginOtpRequestResponse: (state) => state.loginOtpRequestResponse,
  loginFacebookResponse: (state) => state.loginFacebookResponse,
  loggedInWithEmail: (state) => state.loggedInWithEmail,
  otpVerified: (state) => state.otpVerified,
  forgotPasswordResponse: (state) => state.forgotPasswordResponse,
  resetPasswordResponse: (state) => state.resetPasswordResponse,
};

export default reducer;
