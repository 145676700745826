import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import lookupsReducer from "./lookups.reducers";
import authReducer from "./auth.reducers";
import userReducer from "./user.reducers";
import notificationReducer from "./notifications.reducers";
import matchesReducer from "./matches.reducers";
import communicationsReducer from "./communications.reducers";
import premiumReducer from "./premium.reducers";
import productsReducer from "./products.reducers";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    lookups: lookupsReducer,
    auth: authReducer,
    user: userReducer,
    notifications: notificationReducer,
    matches: matchesReducer,
    communications: communicationsReducer,
    premium: premiumReducer,
    products: productsReducer
  });

export default createRootReducer;
