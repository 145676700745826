import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import ReduxThunk from 'redux-thunk';
import premiumFeatureHandler from './middlewares/premiumFeatureHandler';
import errorHandler from './middlewares/errorHandler';
import createRootReducer from './reducers';
const mWare = [ReduxThunk, premiumFeatureHandler, errorHandler];
const composeEnhancers = compose;

const history = createBrowserHistory();

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...mWare))
);

export { history, store };
