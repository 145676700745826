import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "./Popup.css";

// importing icons
import CloseIcon from "@material-ui/icons/Close";


// No need for these now
// import { Textsms } from '@material-ui/icons';
// import { VideoCall } from '@material-ui/icons';
// import { Send } from '@material-ui/icons';
// import { Speed } from "@material-ui/icons/";
// import { LockOpen } from '@material-ui/icons';
// import { Sort } from "@material-ui/icons";
// import { Cached } from '@material-ui/icons';


import messageIcon from "../../images/Popup/massage.svg";
import videoIcon from "../../images/Popup/video-camera.svg";
import planeIcon from "../../images/Popup/paper-plane.svg";
import rocketIcon from "../../images/Popup/Icon ionic-ios-rocket.svg";
import unlockIcon from "../../images/Popup/Icon ionic-ios-unlock.svg";
import filterIcon from "../../images/Popup/Gold Perfances.svg";
import refreshIcon from "../../images/Popup/refresh.svg";

import leftGraphic from "../../images/Popup/slider graphic left.svg";
import rightGraphic from "../../images/Popup/slider graphic right.svg";

import backgroundImage from "../../images/Popup/background.png";

const sliderData = [
    {
        key:"1",
        heading:"5 Messages daily",
        text:"Chat with your preferred matches",
        // icon:<Textsms className="text-qabool-newLightGradient"/>
        icon:<img src={messageIcon} className="mx-auto mb-2" alt="5 Messages daily" />
    },
    {
        key:"2",
        heading:"1 Video Credit",
        text:"Video Call With Your Preferred Matches",
        // icon:<VideoCall className="text-qabool-newLightGradient"/>
        icon:<img src={videoIcon} className="mx-auto mb-2" alt="1 Video Credit" />
    },
    {
        key:"3",
        heading:"Endless Invites",
        text:"Send As Many Invites As You Want",
        // icon:<Send className="text-qabool-newLightGradient"/>
        icon:<img src={planeIcon} className="mx-auto mb-2" alt="Endless Invites" />
    },
    {
        key:"4",
        heading:"Profile Boost",
        text:"Standout And Get More Visibility",
        // icon:<Speed className="text-qabool-newLightGradient"/>
        icon:<img src={rocketIcon} className="mx-auto mb-2" alt="Profile Boost" />
    },
    {
        key:"5",
        heading:"Unlock Photo Filter",
        text:"Get Access To Premium Users’ Photos",
        // icon:<LockOpen className="text-qabool-newLightGradient"/>
        icon:<img src={unlockIcon} className="mx-auto mb-2" alt="Unlock Filter" />
    },
    {
        key:"6",
        heading:"Premium Filters",
        text:"Find Your Preferred Matches Quickly",
        // icon:<Sort className="text-qabool-newLightGradient"/>
        icon:<img src={filterIcon} className="mx-auto mb-2" alt="Premium Filters" />
    },
    {
        key:"7",
        heading:"U-Turn",
        text:"You Can Always Change Your Decision",
        // icon:<Cached className="text-qabool-newLightGradient"/>
        icon:<img src={refreshIcon} className="mx-auto mb-2" alt="U-Turn" />
    },
]


function Popup({ closePopup }) {
    const closeHandler = e =>{
        e.preventDefault();
        closePopup();
    }
    const storeLink = /Mac OS/i.test(navigator.userAgent) ? "https://apps.apple.com/us/app/qaboolhai/id1627652232" : "https://play.google.com/store/apps/details?id=com.qaboolhai";
    return (
        <div className='font-carosSoft p-3 fixed z-[99999] overflow-y-auto h-screen w-screen top-0 left-0 flex justify-center backdrop-blur-sm'>
            <div className='rounded-xl my-auto text-center lg:text-left w-full max-w-[1174px] p-2 md:p-3 bg-black text-white bg-cover bg-no-repeat bg-center' style={{backgroundImage:`url(${backgroundImage})`}}>
                <div className='text-right mb-3'>
                    <button onClick={closeHandler}><CloseIcon /></button>
                </div>
                <div className='flex flex-wrap items-end'>
                    <div className='w-full lg:w-1/2'>
                        <div className='flex lg:flex-nowrap flex-wrap px-0 md:pr-[52px] md:pl-[80px]'>
                            <h2 className='w-full text-[150px] lg:text-[300px] xl:text-[400px] font-extrabold leading-[0.75em] lg:tracking-[-1em] mb-2 lg:mb-0 text-transparent bg-clip-text bg-gradient-to-r from-qabool-gold to-qabool-newLightGradient'>1</h2>
                            <div className="w-full">
                                <h3 className='uppercase text-[25px] sm:text-[40px] xl:text-[47px] leading-[normal] font-semibold'>Week special offer</h3>
                                <p className='text-[18] sm:text-[20px] xl:text-[26px] leading-[normal]'>Become a Gold Member for One Week and unlock all Premium Feature</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-center md:hidden block my-[1rem] relative">
                        <img alt="" src={leftGraphic} className="absolute top-0 left-0 translate-y-3/4"/>
                        <img alt="" src={rightGraphic} className="absolute top-0 right-0 translate-y-3/4"/>
                    <Swiper autoplay={{delay:2000, disableOnInteraction:false}} pagination={true} modules={[Autoplay, Pagination]} className="mySwiper">
                        {
                            sliderData.map(item=>(
                                <SwiperSlide key={item.key}>
                                    <div className='w-full px-2'>
                                        {item.icon}
                                        <h5 className="text-[16px]">{item.heading}</h5>
                                        <p className="text-gray-100 font-light text-[13px]">{item.text}</p>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <div className='px-0 md:pl-[30px] lg:pr-[180px] xl:pr-[200px]'>
                            <p className='text-[22px] sm:text-[40px] xl:text-[51px]'>Now Only For</p>
                            <h2 className={`text-[34px] sm:text-[80px] xl:text-[103px] font-semibold mb-4 md:mb-0 leading-[1.15em]`}>Rs 999</h2>
                            <a rel="noreferrer" href={storeLink} target="_blank" className='block text-center text-lg w-full px-5 py-2 my-4 rounded-3xl bg-gradient-to-r from-qabool-gold to-qabool-newLightGradient'>Sign Up Now</a>
                            <button onClick={closeHandler} className="text-lg w-full px-5 py-2 mb-4 lg:hidden">Cancel</button>
                        </div>
                    </div>
                </div>
                <div className='hidden md:flex flex-wrap justify-center text-center mt-5'>
                    {
                        sliderData.map(item=>(
                            <div className="w-1/4 pb-5 px-2 flex flex-col justify-between" key={item.key}>
                                {item.icon}
                                <div>
                                    <h5 className="font-semibold">{item.heading}</h5>
                                    <p className="text-gray-100 text-xs">{item.text}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='text-center'>
                    <h4 className='text-[12px] md:text-[16px] mb-1'>Recurring Billing, Cancel Anytime </h4>
                    <p className='text-[12px] font-light mb:text-xs'>Payment will be charged to your iTunes account within 24-hours prior to the end of the current period. auto-renewal may be turned off at any time by going to your setting in the iTunes store after purchase. for more information please visit our terms of use and privacy policy</p>
                </div>
            </div>
        </div>
    )
}

export default Popup;