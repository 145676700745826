import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Popup from '../components/Popup/Popup';

export const LocationContext = React.createContext();
export const CountryContext = React.createContext();

const LocationProvider = ({ children }) => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [country_code, setCountryCode] = useState('PK');
  const [country, setCountry] = useState("");
  useEffect(() => {
    // We can get isGoldMember bit from the API and display the popup if the bit is false
    // Displaying true for now
    // setIsPopupActive(true);

    (async () => {
      let resp = await axios.get(
        // 'https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708'
        'https://z63td15z82.execute-api.eu-west-1.amazonaws.com/Prod/payment/client/geolocation/get'
      );
      let { data } = resp.data;
      setCountryCode(data.isInPakistan ? 'PK' : 'UK');
      setCountry(data.countryCode);
      if(data.isInPakistan){
        // setIsPopupActive(true);
      }
    })();
  }, []);
  return (
    <LocationContext.Provider value={country_code}>
      <CountryContext.Provider value={country}>
        {isPopupActive && <Popup closePopup={()=>setIsPopupActive(false)} />}
        {children}
      </CountryContext.Provider>
    </LocationContext.Provider>
  );
};

export default LocationProvider;
