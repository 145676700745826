import React, { useContext, useEffect } from 'react'; //  useEffect
import LocationProvider from './context/Location.context';
import OpenRoutes from './openRoutes';
import whatsappImg from './images/whatsapp-icon.png';
// import ProtectedRoutes from "./protectedRoutes";
// import FullScreenLoaderNew from "./components/fullScreenLoaderNew";
import './App.css';
import './app.scss';
import LoaderIcon from './images/platinum/rolling_loader.gif';
import LoaderProvider, { LoaderContext } from './context/Loader.context';


const LoaderCustom = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.removeAttribute('style');
    };
  }, []);
  return (
    <div className="fixed bg-black bg-opacity-50 left-0 top-0 right-0 bottom-0 z-[99999] flex items-center justify-center">
      <img
        src={LoaderIcon}
        alt="loader icon"
        className="relative rounded-full"
      />
    </div>
  );
};
const AppBase = ({ children }) => {
  const { isLoading } = useContext(LoaderContext);
  return (
    <>
      {isLoading ? <LoaderCustom /> : null}
      {children}
    </>
  );
};

const OpenApp = () => {
  return (
    <LocationProvider>
      <LoaderProvider>
        <AppBase>
          <OpenRoutes />
          <a
            rel="noreferrer"
            href="https://wa.me/+923123111132?text=Welcome To QaboolHai"
            className="hidden md:inline-block fixed bottom-[17%] sm:bottom-[13%] right-[10px] z-[9999]"
            target="_blank"
          >
            <img src={whatsappImg} className="w-[60px]" alt=""></img>
          </a>
        </AppBase>
      </LoaderProvider>
    </LocationProvider>
  );
};

// const ProtectedApp = ({ user }) => {
//   const incomingCallData = useSelector(selectors.getIncomingCallData);
//   const outgoingCallData = useSelector(selectors.getOutgoingCallData);

//   const { pathname } = window.location;
//   if (pathname === "/success") {
//     return "Order placed! You will receive an email confirmation.";
//   } else if (pathname === "/cancel") {
//     return "Order canceled -- continue to shop around and checkout when you're ready.";
//   }

//   return (
//     <AppBase>
//       <ProtectedRoutes user={user} />
//       <PremiumFeaturesHandler user={user} />
//       {(!!incomingCallData || !!outgoingCallData) && (
//         <VideoDialog
//           incomingCallData={incomingCallData}
//           outgoingCallData={outgoingCallData}
//         />
//       )}
//     </AppBase>
//   );
// };

const App = () => {
  // const dispatch = useDispatch();
  // const user = useSelector(selectors.getUser);
  // const gettingUser = useSelector(selectors.gettingUser);

  // const userLoggedIn = isUserLoggedIn();
  // const userLoaded = !!user.createdDate;
  // useEffect(() => {
  //   if (userLoggedIn) {
  //     dispatch(userActions.getUser(true));
  //     dispatch(userActions.getSubscriptionDetails());
  //     dispatch(userActions.getPartnerPreferences());
  //     dispatch(lookupActions.getLookups());
  //     dispatch(commActions.setupMessaging());
  //   }
  // }, []);
  // if (!userLoggedIn) return <OpenApp />;
  // if (gettingUser && window.location.pathname === "/")
  //   return <FullScreenLoader />;
  // if (!userLoaded) return <FullScreenLoader />;
  // return <ProtectedApp user={user} />;

  //**************************************************uncomment the code in phase 2 for registered users!
  return <OpenApp />;
};

export default App;
