import React from "react";
import { makeStyles } from "@material-ui/core";
import Lottie from "lottie-react";
import { pxToRem } from "../shared/utils";
import HeartAnimation from './HeartbeatAnim.json';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    background:
      "transparent linear-gradient(180deg, #8B41F6 0%, #46217B 100%) 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logoImg: {
    height: pxToRem(140),
    width: pxToRem(140),
  },
  loaderImg: {
    height: pxToRem(100),
    width: pxToRem(100),
  },
}));
const FullScreenLoaderNew = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Lottie animationData={ HeartAnimation} />
    </div>
  );
};

export default FullScreenLoaderNew;
