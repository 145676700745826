import produce from "immer";
import actionNames from "./actionNames";

const defaultKeyMaps = {
  value: "title",
};

const keyMapper = (obj, keyMaps) => {
  const newObj = {
    ...obj,
  };
  Object.keys(keyMaps).forEach((key) => {
    newObj[keyMaps[key]] = obj[key];
  });
  return newObj;
};
const defaultKeyMapper = (obj) => keyMapper(obj, defaultKeyMaps);

const initialState = {
  genders: [],
  maritalStatuses: [],
  profileFor: [],
  language: [],
  religion: [],
  religionCommunity: [],
  familyCommunity: [],
  countries: [],
  countryStates: [],
  stateCities: [],
  education: [],
  college: [],
  employer: [],
  profession: [],
  annualIncome: [],
  children: [],
  disability: [],
  community: [],
  ethnicOrigin: [],
  residencyStatus: [],
  siblings: [],
  loading: false,
};

const reducer = produce((newState, action) => {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case actionNames.LOAD_LOOKUPS_LOADING:
      newState.loading = true;
      break;
    case actionNames.LOAD_LOOKUPS_FAILED:
      newState.loading = false;
      break;
    case actionNames.LOAD_LOOKUPS_SUCCESS:
      newState.loading = false;
      const {
        maritalStatusList,
        genderList,
        countryList,
        countryStateList,
        countryCityList,
        annualIncomeList,
        childrenList,
        collegeList,
        educationList,
        employerList,
        familyCommunityList,
        languageList,
        professionList,
        profileForList,
        religionList,
        religionCommunityList,
        disabilityList,
        communityList,
        ethnicOriginList,
        residencyStatusList,
        siblingsList,
      } = payload;
      newState.maritalStatuses = maritalStatusList.map(defaultKeyMapper);
      newState.genders = genderList.map(defaultKeyMapper);
      newState.countries = countryList.map(defaultKeyMapper);
      newState.countryStates = countryStateList.map(defaultKeyMapper);
      newState.stateCities = countryCityList.map(defaultKeyMapper);
      newState.annualIncome = annualIncomeList.map(defaultKeyMapper);
      newState.children = childrenList.map(defaultKeyMapper);
      newState.college = collegeList.map(defaultKeyMapper);
      newState.education = educationList.map(defaultKeyMapper);
      newState.employer = employerList.map(defaultKeyMapper);
      newState.familyCommunity = familyCommunityList.map(defaultKeyMapper);
      newState.language = languageList.map(defaultKeyMapper);
      newState.profession = professionList.map(defaultKeyMapper);
      newState.profileFor = profileForList.map(defaultKeyMapper);
      newState.religion = religionList.map(defaultKeyMapper);
      newState.religionCommunity = religionCommunityList.map(defaultKeyMapper);
      newState.disability = disabilityList.map(defaultKeyMapper);
      newState.community = communityList.map(defaultKeyMapper);
      newState.ethnicOrigin = ethnicOriginList.map(defaultKeyMapper);
      newState.residencyStatus = residencyStatusList.map(defaultKeyMapper);
      newState.siblings = siblingsList.map(defaultKeyMapper);
      break;
    default:
  }
}, initialState);

export const lookupSelectors = {
  loadinglookups: (state) => state.loading,
  genders: (state) => state.genders,
  maritalStatuses: (state) => state.maritalStatuses,
  countries: (state) => state.countries,
  countryStates: (state) => state.countryStates,
  stateCities: (state) => state.stateCities,
  annualIncome: (state) => state.annualIncome,
  children: (state) => state.children,
  college: (state) => state.college,
  education: (state) => state.education,
  employer: (state) => state.employer,
  familyCommunity: (state) => state.familyCommunity,
  heightInFt: (state) => state.heightInFt,
  heightInInches: (state) => state.heightInInches,
  language: (state) => state.language,
  profession: (state) => state.profession,
  profileFor: (state) => state.profileFor,
  religion: (state) => state.religion,
  religionCommunity: (state) => state.religionCommunity,
  disability: (state) => state.disability,
  community: (state) => state.community,
  ethnicOrigin: (state) => state.ethnicOrigin,
  residencyStatus: (state) => state.residencyStatus,
  siblings: (state) => state.siblings,
};

export default reducer;
