import actionNames from "../actionNames";
import { random } from "../../shared/utils";

const blackListedErrors = [
  "SIGNUP_USER_FAILED",
  "LOGIN_MOBILE_FAILED",
  "LOGIN_FACEBOOK_FAILED",
  "LOGIN_EMAIL_FAILED",
  "FORGOT_PASSWORD_FAILED",
  "RESET_PASSWORD_FAILED",
  "VERIFY_OTP_FAILED",
];

export default ({ getState }) => (next) => (action) => {
  if (typeof action !== "object") {
    return next(action);
  }

  const { type, payload } = action;

  if (type.endsWith("_FAILED") && !blackListedErrors.includes(type))
    next({
      type: actionNames.ADD_NOTIFICATION,
      payload: {
        variant: "error",
        message: payload.message,
        key: `noti-${random()}`,
      },
    });

  next({ type, payload });
};
