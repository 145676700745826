import produce, { current } from "immer";
import actionNames from "./actionNames";
const initialState = {
  loading: false,
  performingMatchAction: false,
  matchActionResponse: null,

  pendingActionMatches: [],

  matchInfoShown: false,

  matches: [],
  acceptedQaboolHaiMatches: [],
  sentPendingQaboolHaiMatches: [],
  receivedPendingQaboolHaiMatches: [],
  favoriteMatches: [],
  visitedByMatches: [],
  visitedMatches: [],
  blockedMatches: [],
  passedMatches: [],
};

const reducer = produce((newState, action) => {
  const state = current(newState);
  const { type: actionType, payload } = action;
  switch (actionType) {
    case actionNames.GET_MATCHES_LOADING:
      newState.loading = true;
      break;
    case actionNames.GET_MATCHES_FAILED:
      newState.loading = false;
      break;
    case actionNames.GET_MATCHES_SUCCESS:
      newState.loading = false;
      newState[payload.type] = payload.matches.map((m) => ({
        ...m,
        isMale: m.gender === "Male",
      }));
      break;
    case actionNames.MATCH_ACTION_LOADING:
      newState.performingMatchAction = true;
      newState.matchActionResponse = null;
      if (["pass", "qaboolhai"].includes(payload.type)) {
        newState.pendingActionMatches = [
          ...state.pendingActionMatches,
          state.matches.find((m) => m.userId === payload.userId),
        ];
        newState.matches = state.matches.filter(
          (m) => m.userId !== payload.userId
        );
      }
      if (payload.type === "resetSwipes") {
        newState.passedMatches = [];
      }
      break;
    case actionNames.MATCH_ACTION_SUCCESS:
      newState.performingMatchAction = false;
      newState.matchActionResponse = payload.response;
      if (["pass", "qaboolhai"].includes(payload.type))
        newState.pendingActionMatches = state.pendingActionMatches.filter(
          (m) => m.userId !== payload.userId
        );
      break;
    case actionNames.MATCH_ACTION_FAILED:
      newState.performingMatchAction = false;
      if (["pass", "qaboolhai"].includes(payload.type))
        newState.matches = [
          ...state.matches,
          state.pendingActionMatches.find((m) => m.userId === payload.userId),
        ];
      break;
    case actionNames.RESET_MATCH_ACTION_RESPONSE:
      newState.matchActionResponse = null;
      break;
    case actionNames.SET_MATCH_INFO_SHOWN:
      newState.matchInfoShown = payload.shown;
      break;
    default:
  }
}, initialState);

export const matchesSelectors = {
  loading: (state) => state.loading,
  matches: (state) => state.matches,
  acceptedQaboolHaiMatches: (state) => state.acceptedQaboolHaiMatches,
  sentPendingQaboolHaiMatches: (state) => state.sentPendingQaboolHaiMatches,
  receivedPendingQaboolHaiMatches: (state) =>
    state.receivedPendingQaboolHaiMatches,
  favoriteMatches: (state) => state.favoriteMatches,
  visitedByMatches: (state) => state.visitedByMatches,
  visitedMatches: (state) => state.visitedMatches,
  blockedMatches: (state) => state.blockedMatches,
  passedMatches: (state) => state.passedMatches,
  performingMatchAction: (state) => state.performingMatchAction,
  matchActionResponse: (state) => state.matchActionResponse,
  matchInfoShown: (state) => state.matchInfoShown,
};
export default reducer;
