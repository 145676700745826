import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import FullScreenLoaderNew from './components/fullScreenLoaderNew';
import * as serviceWorker from './serviceWorker';
import './index.css';
import AppBase from './AppBase';

import './i18next';

// const AppBase = React.lazy(() => import('./AppBase'));

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <Suspense fallback={<FullScreenLoaderNew />}>
    <AppBase />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
