import produce, { current } from "immer";
import actionNames from "./actionNames";
import { compareDesc } from "date-fns";

const defaultChats = [];

const initialState = {
  pushEnabled: false,
  loading: false,
  loadingChats: false,
  loadingMessages: false,
  incomingCallData: null,
  outgoingCallData: null,
  initiatingCall: false,
  initCallResponse: null,
  gettingCallAuthToken: false,
  callAuthTokenResp: null,
  notifications: [],
  sendingMessage: false,
  messages: {},
  recentChats: [],
};

const setMessageStatus = (payload, state, status) => ({
  ...state.messages,
  [payload.msg.recipientId]: [
    ...state.messages[payload.msg.recipientId].map((m) => {
      m = { ...m };
      if (m.guid && m.guid === payload.msg.guid) m.status = status;
      return m;
    }),
  ],
});

const reducer = produce((newState, action) => {
  const { type: actionType, payload } = action;
  const state = current(newState);
  switch (actionType) {
    case actionNames.GET_NOTIFICATIONS_LOADING:
      newState.loading = true;
      break;
    case actionNames.GET_NOTIFICATIONS_FAILED:
      newState.loading = false;
      break;
    case actionNames.GET_NOTIFICATIONS_SUCCESS:
      newState.loading = false;
      newState.notifications = payload;
      break;

    case actionNames.NOTIFICATION_EXECUTED:
      const { type, finder } = payload;
      const notifications = [...state.notifications.map((n) => ({ ...n }))];
      const existingNotification = notifications.find(finder);
      if (!existingNotification) break;

      existingNotification.isActioned = true;
      existingNotification.status =
        type === "acceptQaboolHai" ? "accepted" : "rejected";
      newState.notifications = [...notifications];
      break;
    case actionNames.SEND_MESSAGES_LOADING:
      newState.sendingMessage = true;
      newState.messages = {
        ...state.messages,
        [payload.recipientId]: [
          ...(state.messages[payload.recipientId] || []),
          { ...payload, status: "sending" },
        ],
      };
      break;
    case actionNames.SEND_MESSAGES_FAILED:
      newState.sendingMessage = false;
      newState.messages = setMessageStatus(payload, state, "failed");
      break;
    case actionNames.SEND_MESSAGES_SUCCESS:
      newState.sendingMessage = false;
      newState.messages = setMessageStatus(payload, state, "sent");
      if (!state.recentChats.length) break;

      if (state.recentChats.userId === payload.msg.recipientId) break;

      let recipient = null;
      const recipients = [];
      state.recentChats.forEach((r) => {
        if (r.userId === payload.msg.recipientId) {
          recipient = {
            ...r,
            dateTime: new Date(),
            message: payload.msg.message,
          };
        } else recipients.push(r);
      });
      if (!recipient)
        recipient = {
          ...payload.msg.recipient,
          dateTime: new Date(),
          message: payload.msg.message,
        };

      newState.recentChats = [recipient, ...recipients];
      break;
    case actionNames.GET_MESSAGES_LOADING:
      newState.loadingMessages = true;
      break;
    case actionNames.GET_MESSAGES_FAILED:
      newState.loadingMessages = false;
      break;
    case actionNames.GET_MESSAGES_SUCCESS:
      newState.loadingMessages = false;
      if (
        state.messages[payload.recipientId]?.length !== payload.messages.length
      )
        newState.messages = {
          ...state.messages,
          [payload.recipientId]: payload.messages,
        };
      break;
    case actionNames.GET_RECENT_CHATS_LOADING:
      newState.loadingChats = true;
      break;
    case actionNames.GET_RECENT_CHATS_FAILED:
      newState.loadingChats = false;
      break;
    case actionNames.GET_RECENT_CHATS_SUCCESS:
      newState.loadingChats = false;
      newState.recentChats = payload;
      break;
    case actionNames.SET_MESSAGE_RECEIVED:
      newState.recentChats = state.recentChats
        .map((r) =>
          r.userId !== parseInt(payload, 10)
            ? r
            : { ...r, pendingMessage: true, dateTime: new Date() }
        )
        .sort((m1, m2) => compareDesc(m1.dateTime, m2.dateTime));
      break;
    case actionNames.SET_MESSAGE_READ:
      newState.recentChats = state.recentChats.map((r) =>
        r.userId !== parseInt(payload, 10) ? r : { ...r, pendingMessage: false }
      );
      break;
    case actionNames.SET_PUSH_API_ENABLED:
      newState.pushEnabled = true;
      break;
    case actionNames.OUTGOING_VIDEO_CALL:
      newState.outgoingCallData = payload;
      break;
    case actionNames.INITIATE_VIDEO_CALL_LOADING:
      newState.initiatingCall = true;
      newState.initCallResponse = null;
      break;
    case actionNames.INITIATE_VIDEO_CALL_FAILED:
      newState.initiatingCall = false;
      break;
    case actionNames.INITIATE_VIDEO_CALL_SUCCESS:
      newState.initiatingCall = false;
      newState.initCallResponse = payload;
      break;
    case actionNames.INCOMING_VIDEO_CALL:
      newState.incomingCallData = {
        channel: payload,
      };
      break;
    case actionNames.GETTING_VIDEO_CALL_TOKEN_LOADING:
      newState.gettingCallAuthToken = true;
      newState.callAuthTokenResp = null;
      break;
    case actionNames.GETTING_VIDEO_CALL_TOKEN_SUCCESS:
      newState.gettingCallAuthToken = false;
      newState.callAuthTokenResp = payload;
      break;
    case actionNames.GETTING_VIDEO_CALL_TOKEN_FAILED:
      newState.gettingCallAuthToken = false;
      break;
    case actionNames.RESET_CALL_STATE:
      newState.incomingCallData = null;
      newState.outgoingCallData = null;
      newState.initiatingCall = false;
      newState.initCallResponse = null;
      newState.gettingCallAuthToken = false;
      newState.callAuthTokenResp = null;
      break;

    default:
  }
}, initialState);

export const communicationsSelectors = {
  pushEnabled: (state) => state.pushEnabled,
  loading: (state) => state.loading,
  loadingChats: (state) => state.loadingChats,
  loadingMessages: (state) => state.loadingMessages,
  notifications: (state) => state.notifications,
  sendingMessage: (state) => state.sendingMessage,
  messages: (state, userId) => state.messages[userId] || defaultChats,
  recentChats: (state) => state.recentChats,
  incomingCallData: (state) => state.incomingCallData,
  outgoingCallData: (state) => state.outgoingCallData,
  initiatingCall: (state) => state.initiatingCall,
  initCallResponse: (state) => state.initCallResponse,
  gettingCallAuthToken: (state) => state.gettingCallAuthToken,
  callAuthTokenResp: (state) => state.callAuthTokenResp,
};

export default reducer;
