import React from 'react';
import { useState } from 'react';

export const LoaderContext = React.createContext({
  isLoading: false,
  setLoading: Function,
});

const LoaderProvider = ({ children }) => {
  const [isLoading, setLoadingState] = useState(false);
  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        setLoading: setLoadingState,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
