import produce, { current } from "immer";
import actionNames from "./actionNames";
const initialState = {
  notifications: [],
};

const reducer = produce((newState, action) => {
  const state = current(newState);

  const { type: actionType, payload } = action;
  switch (actionType) {
    case actionNames.ADD_NOTIFICATION:
      newState.notifications = [payload, ...state.notifications];
      break;
    case actionNames.REMOVE_NOTIFICATION:
      const key = action.payload.key;
      newState.notifications = [
        ...state.notifications.filter((n) => n.key !== key),
      ];
      break;
    default:
  }
}, initialState);

export const notificationSelectors = {
  notifications: (state) => state.notifications,
};
export default reducer;
