import {
  isToday,
  isYesterday,
  differenceInSeconds,
  toDate,
  format,
  parseISO,
  parse,
  addMinutes,
  isEqual,
  startOfToday,
} from "date-fns";
import { DATE_TIME, DATE, TIME } from "./dateFormats";
import React from "react";
import { useLocation } from "react-router-dom";

export const pxToRem = (px) => `${px / 16}rem`;

export const matchArrays = (arr1 = [], arr2 = []) =>
  [...arr1].sort().join() === [...arr2].sort().join();

export const getChangedObj = (obj1, obj2, skipAttr = []) =>
  Object.keys(obj1).reduce((changedObj, key) => {
    if (skipAttr.includes(key) || obj1[key] === obj2[key]) return changedObj;

    if (Array.isArray(obj1[key])) {
      if (!matchArrays(obj1[key], obj2[key])) changedObj[key] = obj1[key];
      return changedObj;
    }

    changedObj[key] = obj1[key];
    return changedObj;
  }, {});

export const random = (min = 999, max = 999999) =>
  Math.floor(Math.random() * max + min);

export const fileToDataUrl = (file, cb) => {
  if (!file) return;

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function (e) {
    cb(reader.result);
  };
};

export const parseAPITime = (dateTime, formatStr = DATE_TIME) =>
  addMinutes(
    parse(dateTime, formatStr, new Date()),
    -1 * new Date().getTimezoneOffset()
  );

export const convertToAPITime = (dateTime, formatStr = DATE_TIME) =>
  format(addMinutes(dateTime, new Date().getTimezoneOffset()), formatStr);

export const formatDistanceToNow = (date, todayForDayStart) => {
  const now = new Date();
  if (!(date instanceof Date)) date = parseISO(date);
  const diffInSecs = differenceInSeconds(now, toDate(date));
  if (diffInSecs < 60) return `${diffInSecs} sec`;
  else if (diffInSecs < 3600) return `${Math.floor(diffInSecs / 60)} min`;
  else if (isToday(date)) {
    if (todayForDayStart && isEqual(date, startOfToday())) return "Today";

    return format(date, TIME);
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else return format(date, DATE);
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].includes("base64"))
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeType = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return {
    blob: new Blob([ia], { type: mimeType }),
    mimeType,
  };
};

export const isUndefined = (val) => typeof val === "undefined";

export const inchesToFeet = (value) =>
  `${Math.floor(value / 12)}'${
    value % 12 > 0 ? Math.floor(value % 12) + '"' : ""
  }`;

export const truncateString = (str, n) =>
  str.length <= n ? str : str.substr(0, n - 1) + "...";

export const groupBy = (xs, key) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);


export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}